<template>
  
  <div class="wrapper">


    <loading
        :active.sync="loader.active" 
        :color="loader.color"
        :loader="loader.type"
        :z-index="loader.zindex"
        :opacity="loader.opacity"
        :background-color="loader.background"
        :can-cancel="loader.cancancel"
        :is-full-page="loader.fullPage"
        :on-cancel="onCancelLoader"></loading>

    <Header
        :hrefs="hrefs"
        :show-info-copy="showInfoCopy"
        :audio="audio.instance"
        :mute-audio="audio.mute"
        :show-share-buttons="showShareButtons"
        v-on:share="onShare"
        v-on:toggle-share="onToggleShare"
        v-on:toggle-info="onToggleInfo"
        v-on:toggle-audio="onToggleAudio"
    />

    <div class="mosaicwrap">
        <Mosaic ref="Mosaic"
            :touch-device="touchDevice"
            :scrolled="scrolled"
            v-on:scrolled="updateScrolled"
            v-on:zoomed="updateZoomed"
            v-on:mosaic-ready="onMosaicReady"
        />
    </div>
       
    <Info
        :show-info="showInfo"
        :show-info-copy="showInfoCopy"
        v-on:toggle-info-copy="onToggleInfoCopy"
    />

    <Footer
        :touch-device="touchDevice"
        :hrefs="hrefs"
        :scrolled="scrolled"
        :zoomed="zoomed"
    />

  </div>

</template>

<style lang="scss">
  @import "./scss/reset";
  @import "./scss/variables";
  @import "./scss/mixins";
  @import "./scss/media-queries";
  @import "./scss/typography";
  @import "./scss/index";
  @import "./scss/header";
  @import "./scss/footer";
  @import "./scss/info";
  @import "./scss/mosaic";
</style>

<script>

  import Header from '@/components/Header';
  import Footer from '@/components/Footer';
  import Info from '@/components/Info';
  import Mosaic from '@/components/Mosaic';
  import Loading from 'vue-loading-overlay';
  import 'vue-loading-overlay/dist/vue-loading.css';
  
  export default {

    name: 'App',

    components: {
        Loading,
        Footer,
        Header,
        Mosaic,
        Info
    },

    data: () => ({

        showInfo: true,
        showInfoCopy: false,
        scrolled: false,
        zoomed: false,
        touchDevice: false,
        showShareButtons: false,

        loader :{
            active: true,
            fullPage: true,
            color:'#C2AC6C',
            type: 'dots',
            zindex : 11,
            opacity: 0.1,
            background: 'black',
            cancancel: false
        },

  
        audio : {
            instance : false,
            mute: false,
            file : '@/assets/audio/mj-kingofpop-64.mp3',
        },

        hrefs: {
            logo : 'http://www.michaeljackson.com',
            feedback : 'https://www.sonymusic.com/feedback/',
            privacy : 'https://www.sonymusic.com/privacy-policy',
            terms : 'https://www.sonymusic.com/terms-and-conditions',
            matters : 'https://whymusicmatters.com',
        }
    }),

    computed: {
        isTouchDevice (){
          var prefixes = ' -webkit- -moz- -o- -ms- '.split(' ');
          var mq = function(query) {
            return window.matchMedia(query).matches;
          }
          if (('ontouchstart' in window) || window.DocumentTouch && document instanceof DocumentTouch) {
            return true;
          }
          // include the 'heartz' as a way to have a non matching MQ to help terminate the join
          // https://git.io/vznFH
          var query = ['(', prefixes.join('touch-enabled),('), 'heartz', ')'].join('');
          return mq(query);
        }
    },

    created () {

        

        this.touchDevice = this.isTouchDevice;

        //console.log('APP CREATED', this.touchDevice );

        if( this.touchDevice ){
            this.audio.mute = true;
        }

    },
    
    destroyed () { },

    mounted () {

        

        /* VUE is added tabindex to wrapper and was causing a blue outline on home element */
        setTimeout(function(){
         // document.getElementsByClassName("wrapper")[0].removeAttribute("tabindex");
        }, 500 );

        /* Load and start background audio - controlled by Header audio button */
        this.audio.instance = new Audio( require('@/assets/audio/mj-kingofpop-64.mp3') );

        this.audio.instance.addEventListener('ended', function() {
            this.currentTime = 0;
            this.play();
        }, false);
        
        if( this.audio.instance && this.audio.mute === false ){
            this.audio.instance.play();  
        }

        document.addEventListener('gesturestart', function (e) {
            /* QA were getting an issue where they could still zoom into background image - not mosaic. meta viewport settings are most probably not working on some iOS devices. https://stackoverflow.com/questions/4389932/how-do-you-disable-viewport-zooming-on-mobile-safari */
            e.preventDefault();
        });


    },

    methods : {

        ///vm.$refs.Mosaic.toggleMosaic();

        onToggleInfoCopy : function(){

            this.showInfoCopy = !this.showInfoCopy;

            if( !this.showInfoCopy ){
                /* EXPLORE SITE */
                this.showInfo = false;
                this.scrolled = true;
            }
        },


        onToggleShare: function (  ) {


            //console.log('onToggleShare', this.showShareButtons);
            this.showShareButtons = !this.showShareButtons;

        },


        onShare: function ( network ) {


            var url, params;

            url = false;

            switch(network){

                case 'twitter':

                    url = "http://twitter.com/share?";
                    
                    params = {
                        url: "https://honormj.michaeljackson.com", 
                        text: "Share to the HonorMJ photo mosaic how you are making things better for others by giving back to your local or global community",
                        via: "michaeljackson",
                        hashtags: "honorMJ"
                    }

                break;


                case 'facebook':

                    url = 'https://www.facebook.com/sharer/sharer.php?';

                    params = {
                        u: "https://honormj.michaeljackson.com", 
                    }

                break;
           }

           //console.log('onShare', url );

           if(url){
                for(var prop in params) url += '&' + prop + '=' + encodeURIComponent(params[prop]);
                window.open(url, '', 'left=0,top=0,width=550,height=450,personalbar=0,toolbar=0,scrollbars=0,resizable=0');
           }


        },

        onToggleInfo: function (  ) {

            this.showInfo = !this.showInfo;

            if( this.showInfo === true ){
                /* Also open the Info paragraph and change the prompt text. */
                this.showInfoCopy = true;
                this.scrolled = false;
            }

        },

        onToggleAudio: function () {

            this.audio.mute = !this.audio.mute;

            if( this.audio.instance && this.audio.mute === true ){
                this.audio.instance.pause();
            }else if ( this.audio.instance && this.audio.mute === false ){
                this.audio.instance.play();
            }
            
        },

        onMosaicReady: function () {
            /* Mosaic should be ready and on screen, so remove loader */
            this.loader.active = false;
        },

        updateZoomed(val){
            this.zoomed = val;
        },

        updateScrolled(val){
            this.scrolled = val;
            /* Also trigger Info open/close, but close Info paragraph */
            this.showInfo = !val;
            this.showInfoCopy = false;
        },


        onCancelLoader() {
          //console.log('User cancelled the loader.')
        }

    },
  }
</script>