<template>
	<!-- <section id="mosaic" @wheel="onScroll"> -->
	<!-- <section id="mosaic" @wheel="$emit('scroll-wheel')"> -->

	<section id="mosaic" class="square" v-bind:class="{ downsize : mosaic.downsize, supersize : mosaic.supersize, grabbing: mosaic.grabbing, grab : !mosaic.grabbing, ready : mosaic.ready }" v-if="mosaic.initialise">

		<!-- {{ posts.current_page }} / {{ posts.limit_value }} -->

		<div class="signature" v-bind:style="{ 'background-image': 'url('+this.signature.src +')' }" v-if="this.signature.src"></div>



		<!-- LIVE -->
		<ul class="grid" v-if="live">

	  		<li v-bind:data-id="'' + key" class="grid-item" v-on:click="onMosaicClick" v-bind:class="{ active : item.active, clicked : mosaic.clicked }" v-bind:style="{ opacity: computedOpacity }" v-for="(item, key, index) in mosaic.data" :key="index">

	  			<!-- LAZY LOAD -->
	  			<img v-lazy=" (item.source.source == 'Twitter') ? item.image + ':medium' : 'https://www.instagram.com/p/'+item.external_id+'/media/?size=l'" v-if="mosaic.lazyload && item.image && item.active">
			    <img v-lazy=" (item.source.source == 'Twitter') ? item.image +  ':thumb' : 'https://www.instagram.com/p/'+item.external_id+'/media/?size=t'" v-else-if="mosaic.lazyload && item.image">

			    <!-- NORMAL LOAD -->
			    <img v-bind:src=" (item.source.source == 'Twitter') ? item.image + ':medium' : 'https://www.instagram.com/p/'+item.external_id+'/media/?size=l'" v-if="!mosaic.lazyload && item.image && item.active">
			    <img v-bind:src=" (item.source.source == 'Twitter') ? item.image +  ':thumb' : 'https://www.instagram.com/p/'+item.external_id+'/media/?size=t'" v-else-if="!mosaic.lazyload && item.image">
				
			    <!-- MESSAGE OVERLAY -->
			    <div class="message" v-if="item.active || !item.image" v-bind:class="{ fallback : !item.image }">

			    	<p class="text" v-bind:class="{ uht : item.unformatted_message.length < mosaic.messageMax/2 }">{{item.unformatted_message | striphtml | truncate(mosaic.messageMax) }}</p>

			    	<p class="handle bold"><a target="_blank" v-bind:href="item.full_url">@{{item.poster_name}}</a></p>

			    </div>
			    

			    <!-- <p class="label bold number" v-else>{{key+1}}</p> -->
				<!-- <p class="valign" v-bind:class="{ uht : item.unformatted_message.length < mosaic.messageMax/2 }">{{item.unformatted_message | striphtml | truncate(mosaic.messageMax) }}</p> -->
			    
			</li>

		</ul>

		<!-- TESTING -->
	  	<ul class="grid testing" v-else>

	  		<li class="grid-item" v-on:click="onMosaicClick" v-bind:class="{ active : item.active, clicked : mosaic.clicked }" v-bind:style="{ opacity: computedOpacity }" v-for="(item, key, index) in mosaic.data" :key="index">

			    <img v-bind:src="testing.photo + testing.argument[+testing.randomise] + index" v-if="testing.randomise && item.active">
			    <img v-bind:src="testing.thumb + testing.argument[+testing.randomise] + index" v-else-if="testing.randomise">
			    <img v-bind:src="testing.photo + testing.argument[+testing.randomise] + testing.lock_id" v-else-if="item.active">
			    <img v-bind:src="testing.thumb + testing.argument[+testing.randomise] + testing.lock_id" v-else>

			    <p class="label bold">{{key+1}}</p>
			    
			</li>

		</ul>

	</section>


</template>

<style scoped>
</style>

<script>

	//import draggable from 'vuedraggable';

	import panzoom from 'panzoom'; //https://github.com/anvaka/panzoom
	import axios from 'axios';
	import VueAxios from 'vue-axios';
	import cloneDeep from 'clone-deep';
	// import VueLazyload from "vue-lazyload";

	


	export default {

	    name: 'Mosaic',

	    props:['scrolled', 'touchDevice'],

		data: () => ({

	   		live:true, /* USE LIVE JUCIER DATA */

	   		juicer : {
	   			cats : false, /* fill the mosaic with cats! */
	   			development : 'https://www.juicer.io/api/feeds/modengtest',
	   			production : 'https://www.juicer.io/api/feeds/honormj', 
	   			argument : [ '?per=', '&page=' ],
	   			max : 500, // cap data coming in, 0 is unlimited
	   			amount : 100,
	   			page : 1,
	   		},

	   		mosaic :{
	   			el : false,
	   			lazyload: true, 
	   			transform : false,
	   			size : 576, // Check `/src/scss/variables.scss` for current grid size
	   			supersize : false,
	   			downsize : false,
	   			instance : false,
	   			initialise: false,
	   			ready : false,
	   			data : false,
	   			photos : false,
	   			messageMax: 300,
	   			opacity: 0,
	   			minOpacity: 0.1,
	   			grabbing : false,
	   			minOpacity: 0.1,
	   			activeThreshold: 0.75,
	   			maxZoom: 9,
  				minZoom: 1, // lock this to 1 for prod
  				smoothScroll: true,
  				zoomDoubleClickSpeed: 2,
				zoomSpeed: 0.065, // 6.5% per mouse wheel event
				pinchSpeed: 0.75, // zoom two ti
				clicked : false,
	   		},

	   		testing : {
	   			/* Switch `live` to `false` to create a mosaic of stock images. */
	   			lock_id : 33,
	   			randomise : false,
	   			argument : [ '?lock=', '?random=' ],
	   			thumb: 'https://loremflickr.com/100/100',
	   			photo: 'https://loremflickr.com/400/400',
	   		},

	   		signature : {
	   			src : false,
	   			allowHack: true,
	   			start : '2019-06-18',
	   			end : '2019-06-25',

	   		},

	   	}),

		created() {

	        //console.log('***CREATED', this.touchDevice, window.innerWidth);

	        /* TWEAK SETTINGS FOR SMALLER SLOWER DEVICES */
	        if ( true && this.touchDevice && window.innerWidth < 992){


	        	console.log('DOWN SIZE ENABLED');

	        	this.mosaic.size = this.mosaic.size / 4;
	        	this.mosaic.downsize = true;
	        	this.mosaic.maxZoom = 6;
	        	//this.mosaic.minOpacity = 0;
	        }

	        //console.log( this.mosaic );

	        /* GO LARGE FOR BIG SCREENS */
	        if ( false && !this.touchDevice && window.innerWidth > 1366 ){
	        	/* Super size the grid for bigger screens */
	        	this.mosaic.size = this.mosaic.size * 4;
	        	this.mosaic.supersize = true;
	        	//console.log('SUPER SIZE THE MOSAIC');
	        	/* IDEA FOR LATER!!! */
	        }

	        if( this.live ){
	        	this.getJuicerFeed();
	        }
	        
	    },

	   	mounted() {

	   		//console.log('***MOUNTED');

	   		if (!this.live){

	   			/* TRIGGER PLACEHOLDER MOSAIC */

	   			this.mosaic.data = {};
	   			this.mosaic.data = [];

	   			for (var i = 0; i < this.mosaic.size; i++) {
	   				this.mosaic.data[i] = { id: i, active : false };
	   			}

	   			this.mosaic.initialise = true;
	   		}
	   	},

	   	updated(){

	   		//console.log('***UPDATED');

	   		if ( !this.mosaic.initialise || this.mosaic.instance || !document.querySelector('#mosaic') ) {
				return;
			}

        	//console.log('***MOSAIC', document.querySelector('#mosaic')  );

        	this.mosaic.el = document.querySelector('#mosaic');

			this.mosaic.instance = panzoom(this.mosaic.el, {
				maxZoom: this.mosaic.maxZoom,
  				minZoom: this.mosaic.minZoom,
  				smoothScroll: this.mosaic.smoothScroll,
  				zoomDoubleClickSpeed: this.mosaic.zoomDoubleClickSpeed,
				zoomSpeed: this.mosaic.zoomSpeed,
				pinchSpeed: this.mosaic.pinchSpeed,
				onTouch:function(e){
					/* Without this the Mosaic was canceling touch events. */
					return false;
				},
				onDoubleClick: function(e) {
					//console.log('mosaic onDoubleClick');
					return false;
				}
			});

			window.addEventListener('resize', this.onResize);
			this.onResize();

			//console.log( this.mosaic );
			//console.log( this.mosaic.instance );

			this.mosaic.instance.on('zoom', this.onMosaicZoom);
			this.mosaic.instance.on('pan', this.onMosaicPan);
			this.mosaic.instance.on('panstart', this.onMosaicPanStart);
			this.mosaic.instance.on('panend', this.onMosaicPanEnd);
			this.mosaic.instance.on('transform', this.onMosaicTransform);

	   		this.mosaic.photos = document.querySelectorAll('li');

	   		//this.mosaic.instance.pause();

	   		/* Show signature logic */
	   		if ( this.getSignatureDate ){
		   		this.signature.src = require('@/assets/imgs/signature-' + this.getSignatureDate + '.png');
		   	}

	   		this.mosaic.ready = true;
	   		this.$emit( 'mosaic-ready', 	this.mosaic.ready );
	   	},

	   	computed: {

			computedOpacity (){
			  return ( this.mosaic.opacity > this.mosaic.minOpacity ) ? this.mosaic.opacity : this.mosaic.minOpacity;
			},

			countPosts () {
				return this.mosaic.data.length;
			},

			getSignatureDate () {

				const toTwoDigits = num => num < 10 ? '0' + num : num;

				let today = new Date();

				if( this.signature.allowHack && window.location.hash ){
					let hack = new Date( window.location.hash.substring(1) );
					today =  ( this.isValidDate( hack ) ) ? hack : today;
				}

				if( today  <  new Date( this.signature.start ) ){
					return false;
				}else if (today > new Date( this.signature.end ) ){
					return this.signature.end;
				}else{
					let year = today.getFullYear();
					let month = toTwoDigits(today.getMonth() + 1);
					let day = toTwoDigits(today.getDate());
					return `${year}-${month}-${day}`;
				}
			}
		},

	    methods: {

	        onResize(event) {
	        	var width, height, left;
				width = window.innerWidth;
				height = window.innerHeight;

				//console.log( width, height );

				if ( height > width ){
					left = (width - height) / 2;
					//console.log( left, el.style.left );
					this.mosaic.el.style.left = left + 'px';
				}
			},

	        isValidDate: function(d){
			  return d instanceof Date && !isNaN(d);
			},

	        getJuicerFeed: function(){

	        	let url = ( this.juicer.cats ) ? this.juicer.development : this.juicer.production;
	        	url += this.juicer.argument[0] + this.juicer.amount + this.juicer.argument[1] + this.juicer.page;


	        	if( this.mosaic.data ){
	        		var cache = this.mosaic.data;
	        	}

			    axios.get( url ).then(response => (this.mosaic.data = response.data.posts.items )).catch(error => console.log(error)).then(() => {
				    
				    /*
						FOR NOW WE ARE JUST LOOPING AROUND THE POST TILL WE HAVE ENOUGH TO FIT ONTO THE MOSAIC
				    */

				    var count = this.countPosts;

				    //console.log('***JUICER RETURNED', this.juicer.page, count, this.juicer.max);

				    if (cache){
				    	this.mosaic.data = cache.concat(this.mosaic.data);
				    	//console.log( this.mosaic.data );
				    }



				    var limit = (this.juicer.max) ? Math.min(this.mosaic.size, this.juicer.max) : this.mosaic.size;

				    if( count === 100 && count && this.countPosts < limit ){

				    	/* MORE JUICE TO GET */
				    	//console.log('MAX JUICE');
				    	this.juicer.page ++;
				    	this.getJuicerFeed();
				
				    }else{

				    	/* NO MORE JUICE */

				    	if( this.juicer.max && this.juicer.max < this.mosaic.size ){

					    	this.mosaic.data = this.mosaic.data.slice(0, this.juicer.max );
					    }

				    	//console.log('ALL THE JUICE', this.countPosts);
					    //console.log( 'POSTS BEFORE', this.countPosts );

					    while(  this.countPosts < this.mosaic.size ){
					    	let copy = cloneDeep( this.mosaic.data );
					    	this.mosaic.data = this.mosaic.data.concat( copy );
					    }

					    this.mosaic.data = this.mosaic.data.sort(function () {
						    return Math.round(Math.random()) - 0.5;
						});

					    /* In case we have too many */
					    this.mosaic.data = this.mosaic.data.slice(0, this.mosaic.size );

						//console.log( 'POSTS AFTER', this.countPosts );
						//console.log(  this.mosaic.data );

						this.mosaic.initialise = true;

					}

				});

            },

            toggleMosaic(){

            	if(!this.mosaic.instance){
            		return;
            	}

            	if( this.mosaic.instance.isPaused() ){
            		this.mosaic.instance.resume();
            	}else{
            		this.mosaic.instance.pause();  
            	}

            },

		    onMosaicPan (e){

		    },

		    onMosaicPanStart (e){
		    	this.mosaic.grabbing = true;
		    	//console.log( 'PAN START', this.grabbing );
		    },

		    onMosaicPanEnd (e){
		    	this.mosaic.grabbing = false;
		    	//console.log( 'PAN END', this.grabbing );
		    },

		    onMosaicTransform (e){

		    	// if( !this.mosaic.photos || this.touchDevice ){
		    	if( !this.mosaic.photos  ){
		    		return;
		    	}
		    	//this.checkMosaicBounds(); /* NOT FULLY TESTED YET */

				this.checkActiveTile();
		    },

		    checkMosaicBounds (){

		    	/*
		    		TODO: Finish and improve this function. 
		    	*/

		    	var style, transform, transformValues;

				style = window.getComputedStyle(this.mosaic.el, null);

				transform = style.getPropertyValue("-webkit-transform") ||
					style.getPropertyValue("-moz-transform") ||
					style.getPropertyValue("-ms-transform") ||
					style.getPropertyValue("-o-transform") ||
					style.getPropertyValue("transform") ||
					"Either no transform set, or browser doesn't do getComputedStyle";

				transformValues = transform.replace(/[^\d,.]/g,'').split(',');

				var bounce = 100;

				if (
					transformValues[4] + bounce > (window.innerWidth  || document.documentElement.clientWidth) ||
					transformValues[5] + bounce > (window.innerHeight || document.documentElement.clientHeight)||
					transformValues[4] - bounce < (-(window.innerWidth) || -(document.documentElement.clientWidth)) ||
					transformValues[5] - bounce < (-(window.innerHeight) || -(document.documentElement.clientHeight))

				) {
					/* OFF PAGE, RESET MOSAIC */
					this.mosaic.el.style.transform = this.mosaic.transform;
					return;
				}

				this.mosaic.transform = transform; //-cache transform for bounding bounce back
	
		    },

		    checkActiveTile (){

				var activeID, bounding, count, parent;

				parent = this;
				count = 0;

				[].forEach.call(this.mosaic.photos, function(photo) {

					parent.mosaic.data[count].active = false;

					if ( parent.mosaic.opacity > parent.mosaic.activeThreshold && !parent.touchDevice ){

						bounding = photo.getBoundingClientRect();

						if (
							bounding.top >= 0 &&
							bounding.left >= 0 &&
							bounding.right <= (window.innerWidth || document.documentElement.clientWidth) &&
							bounding.bottom <= (window.innerHeight || document.documentElement.clientHeight)
						) {
							/* IN VIEW */
							activeID = count;
						}

					}

					count ++;

				});

				if( activeID || activeID === 0 ){
					//console.log( 'ACTIVE', activePhoto, this.posts.items[activePhoto], this.posts.items );
					this.mosaic.data[activeID].active = true;
				}

		    },

	        onMosaicZoom (e){

	        	var currentPercentZoom;

				currentPercentZoom = ((e.getTransform().scale - this.mosaic.instance.getMinZoom()) * 100) / (this.mosaic.instance.getMaxZoom() - this.mosaic.instance.getMinZoom());

				currentPercentZoom = Math.round(currentPercentZoom * 100) / 100;

				this.mosaic.opacity = currentPercentZoom / 100;

				this.$emit('scrolled', ( this.mosaic.opacity > 0 ) ? true : false );
				this.$emit('zoomed', ( this.mosaic.opacity > this.mosaic.activeThreshold ) ? true : false );

	        },

	        onMosaicClick (e){

	        	this.mosaic.clicked = false;

	        	if ( this.touchDevice && this.mosaic.opacity  > this.mosaic.activeThreshold ){

	        		function getNodeindex( elm ){ 
					    var c = elm.parentNode.children, i = 0;
					    for(; i < c.length; i++ )
					        if( c[i] == elm ) return i;
					}

					//console.log( getNodeindex( e.currentTarget ) );

	        		var count = 0;
	        		var posts = this.mosaic.data;

	    //     		[].forEach.call(this.mosaic.photos, function(photo) {
	    //     			posts.items[count].active = false;
	    //     			count ++;
					// });

	        		var activePhoto = getNodeindex( e.currentTarget );


	        		if( activePhoto || activePhoto === 0 ){
						this.mosaic.data[activePhoto].active = true;
					}
					
					this.mosaic.clicked = true;

	        	}

	        },


	        randomNumber : function(){
				return Math.floor(Math.random() * (10 - 1 + 1)) + 1;
		    },


	    },
	}
</script>