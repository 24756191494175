<template>
	<header>

		<a target="_blank" v-bind:href="hrefs.logo">
			<div class="logo"></div>
		</a>

		<div class="buttons" v-bind:class="{ expanded: showShareButtons }">


			<button class="btn twitter" v-on:click="$emit( 'share', 'twitter' )">
				<img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAEiSURBVEhL7dSxSgNBFIXh1WBAUSy0tPQFbLVVsLNPqa3PICgKVhaKlVUCaSxTxVYwWgg2NoIGgoUgCGphiCj6H3EhTO7MznYKe+AjJJk9mR1uNinyLzOGTdyh9/u6hVGUsYINmNmHFvmi8ha+DPqhF9xiBmY+0MDEz7vBaKdWeaqLI6zDzBO0ULtY0AdO2nBLXTV4T+EA/Yt1HKuYhc5Yd9j/vWUI3ozjFNaFMV4RzDYWcQarIMsNgqnCujBWHcHoiHxjGKOCzAxjDeewSnweoP9JZo6hcXyDVeTjnX03U2jCKvE5QQm5ModdfMIqTV1jGlEZge5gHjt4hlWa0s4nEZVlXMEqcnWgidFA5I52v4dLPOId97jAIZYQeuIW+VNJkm8Y172PVdiJkgAAAABJRU5ErkJggg==">
			</button>


			<button class="btn facebook" v-on:click="$emit( 'share', 'facebook' )">
				<img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAACDSURBVEhL7c6xDUBAFIfx67QSAyjMYQyJMIEJLGEGeyno9SoKvusulyeSu5MQvuTX/t9TnytGhxEbFsyo4V2CAbuggXf6c2lcC3Jggjnao0SBDN6tMA9ECJo5rgXvlgP26JkWTkljkgpOSWOSHE5JY5IU3tmjwfsPXPYfuOz9B56YUgdrSnAM0COl1wAAAABJRU5ErkJggg==">
			</button>

			<button class="btn share" v-on:click="$emit( 'toggle-share' )">
				<i class="material-icons-outlined">share</i>
			</button>

			<button class="btn sound" v-bind:class="{ disable: !audio }" v-on:click="$emit('toggle-audio')">
				<i class="material-icons-outlined" v-if="muteAudio">volume_mute</i>
				<i class="material-icons-outlined" v-else>volume_up</i>
			</button>

			<button class="btn info" v-on:click="$emit( 'toggle-info' )">
				<i class="material-icons-outlined">info</i>
			</button>
			
		</div>

	</header>
</template>

<script>
	export default {
	    name: 'Header',
	    props: ['hrefs', 'showInfoCopy', 'audio', 'muteAudio', 'showShareButtons']
	}
</script>

