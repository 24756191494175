<template>

    <section id="info" v-bind:class="{ hide: !showInfo }">
        
        <div class="fade" v-bind:class="{ show: showInfoCopy }"></div>

        <div class="wrap">

            <h1 class="title uppercase center">#HONOR<span class="bold">MJ</span></h1>

            <p class="center bold" v-bind:class="{ show: showInfoCopy }">
                As the 10-year anniversary of Michael's passing nears, what better way to remember Michael than to pay tribute to his lifelong commitment to helping those in need by focusing on his inspiring legacy of philanthropic work. Share how you are making things better for others by giving back to your local or global community. Post an image representing your humanitarian efforts honoring Michael on Instagram or Twitter using the hashtag <span class="uppercase">#HonorMJ</span> and your post will be added to this worldwide photo mosaic.
        	</p>

            <button class="btn" v-on:click="$emit('toggle-info-copy')">
                <h2 class="cta center uppercase bold" v-if="showInfoCopy">Explore</h2>
                <h2 class="cta center uppercase bold" v-else>Info</h2>
            </button>

        </div>



    </section>

</template>

<style scoped>
</style>

<script>
	export default {
	    name: 'Info',
	    props: ['showInfo','showInfoCopy']
	}
</script>