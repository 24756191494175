<template>
	<footer>

		<div class="prompt">



            <h5 class="center uppercase bold" v-if="scrolled && zoomed && touchDevice">Tap to view</h5>
            <h5 class="center uppercase bold" v-else-if="scrolled && zoomed">Pan to view</h5>

            <h5 class="center uppercase bold" v-else-if="scrolled && touchDevice">Touch and drag</h5>
            <h5 class="center uppercase bold" v-else-if="scrolled">Click and drag</h5>

            <h5 class="center uppercase bold" v-else-if="touchDevice">Pinch to zoom</h5>
			<h5 class="center uppercase bold" v-else>Scroll to explore</h5>
        </div>

		<p class="small"><span class="bold"><i class="material-icons">copyright</i>2019 Sony Music Entertainment. All rights reserved</span>&nbsp;|&nbsp;<a target="_blank" v-bind:href="hrefs.feedback">Send Us Feedback</a>&nbsp;|&nbsp;<a target="_blank" v-bind:href="hrefs.privacy">Privacy Policy</a>&nbsp;|&nbsp;<a target="_blank" v-bind:href="hrefs.terms">Terms & Conditions</a>&nbsp;|&nbsp;<a target="_blank" v-bind:href="hrefs.matters">Why Music Matters</a></p>

	</footer>
</template>
<script>
	export default {
	    name: 'Footer',
	    props:['scrolled', 'zoomed', 'hrefs', 'touchDevice'],
	}
</script>