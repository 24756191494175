import Vue from 'vue'
import App from './App.vue'
import VueLazyload from "vue-lazyload";

Vue.config.productionTip = false

Vue.filter('striphtml', function (value) {
    var div = document.createElement("div");
    div.innerHTML = value;
    var text = div.textContent || div.innerText || "";
    return text;
});

Vue.filter('truncate', function (value, limit) {
    if (value.length > limit) {
    value = value.substring(0, (limit - 3)) + '...';
    }
    return value;
});

Vue.filter('urlexists', function (url) {
    var http = new XMLHttpRequest();
    http.open('HEAD', url, false);
    http.send();
    return (http.status != 404) ? false : true;
});


Vue.use(VueLazyload, {
    preLoad: 1.3,
    error: 'load-error.png' ,
    loading: 'load-tile.svg',
    attempt: 1,
    listenEvents: ['scroll', 'wheel', 'mousewheel', 'resize', 'touchmove'],


    /*
    adapter: {
]
        loading (listender, Init) {
            //console.log('loading')
        },

        error (listender, Init) {
            console.log('error', listender.src );
            listender.el.setAttribute('data-src', 'load-error.png');
            //listender.src = 'load-error.png'
        }

    }
    */


})

new Vue({
    el: '#app',
    components: { App },
    template: '<App/>'
})